import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, storage } from "./firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import "./BookingDetails.css"; // Tu archivo de estilos CSS
import { format } from "date-fns";
import { es } from "date-fns/locale";

function BookingDetails() {
  const { bookingId } = useParams();
  const [booking, setBooking] = useState(null);
  const [firmaUrl, setFirmaUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const bookingDocRef = doc(db, "bookings", bookingId);
        const bookingDocSnap = await getDoc(bookingDocRef);
        if (bookingDocSnap.exists()) {
          const bookingData = bookingDocSnap.data();
          setBooking({ id: bookingDocSnap.id, ...bookingData }); // Agregar id al objeto booking

          // Obtener la URL de la firma desde Storage (si existe)
          if (bookingData.firma) {
            const firmaRef = ref(storage, bookingData.firma);
            const url = await getDownloadURL(firmaRef);
            setFirmaUrl(url);
          }
        } else {
          console.log("No se encontró la reserva.");
        }
      } catch (error) {
        console.error("Error al obtener los detalles de la reserva:", error);
      }
    };

    fetchBooking();
  }, [bookingId]);

  if (!booking) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="booking-details">
      <button onClick={() => navigate(-1)}>Volver a la Lista</button>{" "}
      {/* Botón para volver */}
      <h2>Detalles de la Reserva</h2>
      <p>
        <strong>ID de Reserva:</strong> {booking.id}
      </p>
      <p>
        <strong>Cliente:</strong> {booking.nombre}
      </p>
      <p>
        <strong>Email:</strong> {booking.email}
      </p>
      <p>
        <strong>Phone:</strong> {booking.phone}
      </p>
      <p>
        <strong>Tour:</strong> {booking.tourNombre}
      </p>
      <p>
        <strong>Fecha de reseva:</strong>{" "}
        {format(new Date(booking.createdAt.toDate()), "dd/MM/yyyy", {
          locale: es,
        })}
      </p>
      <p>
        <strong>Fecha del Tour:</strong>{" "}
        {format(new Date(booking.fecha.toDate()), "dd/MM/yyyy", { locale: es })}
      </p>
      <p>
        <strong>Hora:</strong> {booking.hora}
      </p>
      <p>
        <strong>Metodo de pago:</strong> {booking.metododepago}
      </p>
      <p>
        <strong>Numero de Comprobante:</strong> {booking.orderId}
      </p>
      <p>
        <strong>Estado:</strong> {booking.status}
      </p>
      <p>
        <strong>Precio Total:</strong> ${booking.precioTotal}
      </p>
      {/* Opciones seleccionadas */}
      {booking.opcionesSeleccionadas &&
        booking.opcionesSeleccionadas.length > 0 && (
          <div>
            <h3>Opciones Seleccionadas:</h3>
            <ul>
              {booking.opcionesSeleccionadas.map((opcion, index) => (
                <li key={index}>
                  {opcion.nombre} (Cantidad: {opcion.cantidad}) - $
                  {opcion.precio}
                </li>
              ))}
            </ul>
          </div>
        )}
      {/* Firma */}
      {firmaUrl && (
        <div>
          <h3>Firma:</h3>
          <img src={firmaUrl} alt="Firma del cliente" />
        </div>
      )}
    </div>
  );
}

export default BookingDetails;

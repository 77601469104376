// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Importar getStorage

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyApUOcfUlJEgqIQtDJLkwJl_DceDJmQ8iA",
  authDomain: "megamanagertours.firebaseapp.com",
  projectId: "megamanagertours",
  storageBucket: "megamanagertours.appspot.com",
  messagingSenderId: "945835795481",
  appId: "1:945835795481:web:396d9460c1347c17ffb688",
  measurementId: "G-FHP8PEKJ9E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app); // Exportar storage

export { app }; // Exportar app

import React, { useState } from "react";
import { db, storage } from "./firebaseConfig"; // Importa tu configuración de Firebase
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./AddTourForm.css"; // Tu archivo de estilos CSS
import { useAuth } from "./AuthContext"; // Importar useAuth para obtener el tenantId
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddTourForm({ onAddTour }) {
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState({ es: "", en: "" });
  const [precio, setPrecio] = useState("");
  const [imagen, setImagen] = useState(null);
  const [horasDisponibles, setHorasDisponibles] = useState([]); // Array de horas disponibles
  const [descripcionBreve, setDescripcionBreve] = useState({ es: "", en: "" }); // Estado para la descripción breve
  const { tenantId } = useAuth(); // Obtener el tenantId del contexto
  const [opciones, setOpciones] = useState([{ nombre: "", precio: "" }]); // Array de opciones con nombre y precio
  const [fechasDisponibles, setFechasDisponibles] = useState([]);
  const [nuevaFecha, setNuevaFecha] = useState("");
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState("es"); // Estado para el idioma seleccionado

  const handleAgregarFecha = () => {
    if (nuevaFecha) {
      setFechasDisponibles([...fechasDisponibles, nuevaFecha]);
      setNuevaFecha(null); // Limpiamos la fecha seleccionada después de agregarla
    }
  };

  const handleRemoveHora = (index) => {
    setHorasDisponibles(horasDisponibles.filter((_, i) => i !== index)); // Filtrar la hora a eliminar
  };

  const handleAddOpcion = () => {
    setOpciones([...opciones, { nombre: "", precio: "" }]);
  };

  const handleOpcionChange = (index, field, value) => {
    const nuevasOpciones = [...opciones];
    nuevasOpciones[index][field] = value;
    setOpciones(nuevasOpciones);
  };

  const handleRemoveOpcion = (index) => {
    setOpciones(opciones.filter((_, i) => i !== index));
  };

  const handleAddHora = () => {
    setHorasDisponibles([...horasDisponibles, ""]); // Agregar un nuevo campo vacío
  };

  const handleHoraChange = (index, value) => {
    const nuevasHoras = [...horasDisponibles];
    nuevasHoras[index] = value;
    setHorasDisponibles(nuevasHoras);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 1. Subir imagen a Firebase Storage
    const storageRef = ref(storage, `tour_images/${imagen.name}`);
    await uploadBytes(storageRef, imagen);
    const imageUrl = await getDownloadURL(storageRef);

    // 2. Guardar datos del tour en Firestore
    const tourData = {
      tenantId: tenantId,
      nombre,
      descripcionBreve,
      descripcion,
      precio: parseFloat(precio), // Asegúrate de que el precio sea un número
      image: imageUrl,
      horasDisponibles: horasDisponibles.filter((hora) => hora !== ""), // Filtrar horas vacías
      fechasDisponibles: fechasDisponibles.map((date) => date.toISOString()), // Convertimos las fechas a formato ISO

      opciones: opciones.filter(
        (opcion) => opcion.nombre !== "" && opcion.precio !== ""
      ), // Filtrar opciones vacías
    };

    try {
      await addDoc(collection(db, "tours"), tourData);
      onAddTour(tourData); // Notificar al componente padre que se agregó un tour
      // Puedes limpiar los campos del formulario aquí si lo deseas
    } catch (error) {
      console.error("Error al agregar el tour:", error);
      // Manejar el error (mostrar un mensaje al usuario, etc.)
    }
  };

  return (
    <div>
      <h2>Añadir Nuevo Tour</h2>
      <form onSubmit={handleSubmit} className="add-tour-form">
        <label>Nombre del Tour:</label>

        <input
          type="text"
          placeholder="Nombre del tour"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          required
        />
        <div>
          <label>Idioma:</label>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="es">Español</option>
            <option value="en">Inglés</option>
          </select>
        </div>
        {language === "es" && (
          <>
            <div>
              <label>Descripción Breve (ES):</label>
              <textarea
                value={descripcionBreve.es}
                onChange={(e) =>
                  setDescripcionBreve({
                    ...descripcionBreve,
                    es: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label>Descripción (ES):</label>
              <textarea
                value={descripcion.es}
                onChange={(e) =>
                  setDescripcion({ ...descripcion, es: e.target.value })
                }
                required
              />
            </div>
          </>
        )}
        {language === "en" && (
          <>
            <div>
              <label>Descripción Breve (EN):</label>
              <textarea
                value={descripcionBreve.en}
                onChange={(e) =>
                  setDescripcionBreve({
                    ...descripcionBreve,
                    en: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label>Descripción (EN):</label>
              <textarea
                value={descripcion.en}
                onChange={(e) =>
                  setDescripcion({ ...descripcion, en: e.target.value })
                }
                required
              />
            </div>
          </>
        )}
        <label>Precio:</label>

        <input
          type="number"
          placeholder="Precio"
          value={precio}
          onChange={(e) => setPrecio(e.target.value)}
          required
        />

        <label>Imagen:</label>

        <input
          type="file"
          onChange={(e) => setImagen(e.target.files[0])}
          required
        />
        <div className="opciones-container">
          <h3>Opciones del Tour</h3>
          {opciones.map((opcion, index) => (
            <div key={index} className="opcion-input">
              <input
                type="text"
                placeholder="Nombre de la opción"
                value={opcion.nombre}
                onChange={(e) =>
                  handleOpcionChange(index, "nombre", e.target.value)
                }
              />
              <input
                type="number"
                placeholder="Precio"
                value={opcion.precio}
                onChange={(e) =>
                  handleOpcionChange(index, "precio", e.target.value)
                }
              />
              <button type="button" onClick={() => handleRemoveOpcion(index)}>
                Eliminar
              </button>
            </div>
          ))}
          <button type="button" onClick={handleAddOpcion}>
            Agregar Opción
          </button>
        </div>

        <h3>Horas Disponibles</h3>
        {horasDisponibles.map((hora, index) => (
          <div key={index} className="hora-input">
            <input
              type="time"
              value={hora}
              onChange={(e) => handleHoraChange(index, e.target.value)}
            />
            <button type="button" onClick={() => handleRemoveHora(index)}>
              Eliminar
            </button>
          </div>
        ))}

        <div className="button-container">
          {/* Contenedor para los botones */}
          <button type="button" onClick={handleAddHora}>
            Agregar Hora
          </button>
          <button type="submit">Agregar Tour</button>
        </div>
      </form>
    </div>
  );
}

export default AddTourForm;

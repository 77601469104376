import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { db } from "./firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"; // Importar Recharts para gráficos
import "./Estadisticas.css";
import {
  format,
  getWeek,
  getYear,
  endOfDay,
  startOfDay,
  endOfWeek,
  startOfWeek,
  endOfMonth,
  startOfMonth,
} from "date-fns";
import { es } from "date-fns/locale";

function Estadisticas() {
  const [totalTours, setTotalTours] = useState(0);
  const [totalReservas, setTotalReservas] = useState(0);
  const [reservasPorTour, setReservasPorTour] = useState({}); // Objeto para almacenar reservas por tour
  const [reservasPorOpcion, setReservasPorOpcion] = useState({});
  const [opcionMasPopular, setOpcionMasPopular] = useState(null);
  const [opcionMenosPopular, setOpcionMenosPopular] = useState(null);
  const [ingresosTotales, setIngresosTotales] = useState(0);
  const [ingresosPorPeriodo, setIngresosPorPeriodo] = useState({});
  const [period, setPeriod] = useState("day"); // Estado para controlar el período (día, semana, mes)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);

  const { tenantId } = useParams(); // Obtener el tenantId de la URL

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        const start = startOfDay(startDate);
        const end = endOfDay(endDate);

        const toursQuery = query(
          collection(db, "tours"),
          where("tenantId", "==", tenantId)
        );
        const bookingsQuery = query(
          collection(db, "bookings"),
          where("tenantId", "==", tenantId),
          where("fecha", ">=", start),
          where("fecha", "<=", end)
        );

        const [toursSnapshot, bookingsSnapshot] = await Promise.all([
          getDocs(toursQuery),
          getDocs(bookingsQuery),
        ]);

        setTotalTours(toursSnapshot.size);
        setTotalReservas(bookingsSnapshot.size);

        const reservasPorTourData = {};
        const tempReservasPorOpcion = {};
        const ingresosPorPeriodo = {};
        let totalIngresos = 0;

        bookingsSnapshot.forEach((doc) => {
          const data = doc.data();
          const tourNombre = data.tourNombre;
          reservasPorTourData[tourNombre] =
            (reservasPorTourData[tourNombre] || 0) + 1;

          const opcionesSeleccionadas = data.opcionesSeleccionadas || [];
          opcionesSeleccionadas.forEach((opcion) => {
            const nombreOpcion = opcion.nombre;
            tempReservasPorOpcion[nombreOpcion] =
              (tempReservasPorOpcion[nombreOpcion] || 0) + opcion.cantidad;
          });

          const fechaReserva = data.fecha.toDate();
          const periodo = getPeriodo(fechaReserva, period);
          ingresosPorPeriodo[periodo] =
            (ingresosPorPeriodo[periodo] || 0) + parseFloat(data.precioTotal);
          totalIngresos += parseFloat(data.precioTotal);
        });

        setReservasPorTour(reservasPorTourData);
        setReservasPorOpcion(tempReservasPorOpcion);
        setIngresosPorPeriodo(ingresosPorPeriodo);
        setIngresosTotales(totalIngresos);

        const popularidadOpciones = Object.entries(tempReservasPorOpcion);
        if (popularidadOpciones.length > 0) {
          popularidadOpciones.sort((a, b) => b[1] - a[1]);
          setOpcionMasPopular(popularidadOpciones[0][0]);
          setOpcionMenosPopular(
            popularidadOpciones[popularidadOpciones.length - 1][0]
          );
        } else {
          setOpcionMasPopular(null);
          setOpcionMenosPopular(null);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error al obtener estadísticas:", error);
        setLoading(false);
      }
    };

    fetchStats();
  }, [tenantId, period, startDate, endDate]);

  const dataopciones = Object.entries(reservasPorOpcion).map(
    ([nombreOpcion, reservas]) => ({
      nombre: nombreOpcion, // Usar nombreOpcion como clave
      reservas,
    })
  );
  const data = Object.entries(reservasPorTour).map(
    ([tourNombre, reservas]) => ({
      nombre: tourNombre,
      reservas,
    })
  );

  function getPeriodo(fecha, periodo) {
    if (periodo === "day") {
      return format(fecha, "yyyy-MM-dd");
    } else if (periodo === "week") {
      return `Semana ${getWeek(fecha)} de ${format(fecha, "yyyy")}`; // Puedes usar date-fns para obtener el número de semana
    } else if (periodo === "month") {
      return format(fecha, "MMMM yyyy", { locale: es }); // Español
    }
  }

  function getStartDate(period, now) {
    if (period === "day") {
      return now;
    } else if (period === "week") {
      const startOfWeek = startOfWeek(now);
      return startOfWeek;
    } else if (period === "month") {
      const startOfMonth = startOfMonth(now);
      return startOfMonth;
    }
  }

  // Función auxiliar para obtener la fecha de fin del período
  function getEndDate(period, now) {
    if (period === "day") {
      return endOfDay(now);
    } else if (period === "week") {
      const endOfWeek = endOfWeek(now);
      return endOfWeek;
    } else if (period === "month") {
      const endOfMonth = endOfMonth(now);
      return endOfMonth;
    }
  }

  return (
    <div className="estadisticas">
      <div className="stats-grid">
        <div className="stats-card">
          <h3>Total de Tours</h3>
          <p>{totalTours}</p>
        </div>
        <div className="stats-card">
          <h3>Total de Reservas</h3>
          <p>{totalReservas}</p>
        </div>
        <div className="stats-card">
          <h3>Opción Más Popular</h3>
          <p>{opcionMasPopular || "N/A"}</p>
        </div>
        <div className="stats-card">
          <h3>Opción Menos Popular</h3>
          <p>{opcionMenosPopular || "N/A"}</p>
        </div>
        <div className="stats-card">
          <h3>Ingresos Totales</h3>
          <p>{ingresosTotales.toFixed(2)}€</p>{" "}
          {/* Ajusta la moneda según sea necesario */}
        </div>
      </div>
      <div className="chart-container">
        <div className="date-picker-container">
          <label>Fecha de Inicio: </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <label>Fecha de Fin: </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
        <h3>
          Ingresos por{" "}
          {period === "day" ? "Día" : period === "week" ? "Semana" : "Mes"}
        </h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={Object.entries(ingresosPorPeriodo).map(
              ([periodo, ingresos]) => ({
                nombre: periodo,
                ingresos,
              })
            )}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="nombre" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="ingresos" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
        <h3>Reservas por Opción</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={dataopciones}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="nombre" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="reservas" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
        <h3>Reservas por Tour</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="nombre" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="reservas" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default Estadisticas;

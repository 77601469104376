import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { db } from "./firebaseConfig";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import AddTourForm from "./AddTourForm"; // Asegúrate de tener este componente
import TourList from "./TourList"; // Asegúrate de tener este componente
import BookingList from "./BookingList"; // Asegúrate de tener este componente
import "./Dashboard.css";
import { Link } from "react-router-dom"; // Asegúrate de importar Link
import {
  FaChartBar,
  FaUserCircle,
  FaSuitcase,
  FaSignOutAlt,
  FaPlusCircle,
} from "react-icons/fa";
import Dialog from "@mui/material/Dialog"; // Importar Dialog de Material UI
import { useParams } from "react-router-dom";

function Dashboard() {
  const { tenantId } = useParams();

  const { logout } = useAuth();
  const [tours, setTours] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [showAddTourForm, setShowAddTourForm] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleAddTour = async (newTourData) => {
    // Lógica para agregar un nuevo tour a Firestore
    // ...
    setShowAddTourForm(false); // Ocultar el formulario después de agregar
  };

  return (
    <div className="dashboard">
      <div className={`sidebar ${sidebarCollapsed ? "collapsed" : ""}`}>
        {/* Botón para colapsar/expandir */}

        <h3>Opciones</h3>
        <ul>
          <li>
            <Link
              to={`/dashboard/${tenantId}/estadisticas`}
              className="sidebar-link"
            >
              <span className="sidebar-icon">
                <FaChartBar />
              </span>
              <span
                className={`sidebar-text ${sidebarCollapsed ? "hidden" : ""}`}
              >
                Estadísticas
              </span>
            </Link>
          </li>
          <li>
            <Link to={`/dashboard/${tenantId}/cuenta`} className="sidebar-link">
              <span className="sidebar-icon">
                <FaUserCircle />
              </span>
              <span
                className={`sidebar-text ${sidebarCollapsed ? "hidden" : ""}`}
              >
                Información de la Cuenta
              </span>
            </Link>
          </li>
          <li>
            <Link to={`/dashboard/${tenantId}/tours`} className="sidebar-link">
              <span className="sidebar-icon">
                <FaSuitcase />
              </span>
              <span
                className={`sidebar-text ${sidebarCollapsed ? "hidden" : ""}`}
              >
                Tours
              </span>
            </Link>
          </li>
          <li>
            <button
              onClick={() => setShowAddTourForm(true)}
              className="sidebar-link"
            >
              <span className="sidebar-icon">
                <FaPlusCircle />
              </span>
              <span
                className={`sidebar-text ${sidebarCollapsed ? "hidden" : ""}`}
              >
                Agregar Tour
              </span>
            </button>
          </li>
          <li>
            <button onClick={logout} className="sidebar-link logout-button">
              <span className="sidebar-icon">
                <FaSignOutAlt />
              </span>
              <span
                className={`sidebar-text ${sidebarCollapsed ? "hidden" : ""}`}
              >
                Cerrar Sesión
              </span>
            </button>
          </li>
          {/* Agrega más opciones según tus necesidades */}
        </ul>
      </div>

      <div className="content">
        <h2>Dashboard</h2>
        <div className="card">
          <Dialog
            open={showAddTourForm}
            onClose={() => setShowAddTourForm(false)}
          >
            <AddTourForm onAddTour={handleAddTour} />
          </Dialog>
          <TourList />
        </div>

        <div className="card">
          <h3>Reservas</h3>
          <BookingList />
        </div>
      </div>

      <button
        onClick={() => setShowAddTourForm(true)}
        className="add-tour-button"
      >
        <FaPlusCircle />
      </button>
    </div>
  );
}

export default Dashboard;

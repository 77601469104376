import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./AuthContext";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { AuthProvider } from "./AuthContext";
import Estadisticas from "./Estadisticas"; // Asegúrate de importar Estadisticas
import Cuenta from "./Cuenta"; // Componente para información de la cuenta
import BookingDetails from "./BookingDetails"; // Importa el componente BookingDetails

function App() {
  const { currentUser, tenantId } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/booking/:bookingId" element={<BookingDetails />} />

        <Route
          path="/dashboard/:tenantId/*"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Navigate
              to={
                currentUser
                  ? `/dashboard/${tenantId}` // Redirigir a /dashboard/:tenantId
                  : "/login"
              }
              replace
            />
          }
        />
        <Route
          path="/dashboard/:tenantId/estadisticas"
          element={
            <ProtectedRoute>
              <Estadisticas />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/:tenantId/cuenta"
          element={
            <ProtectedRoute>
              <Cuenta />
            </ProtectedRoute>
          }
        />

        <Route
          path="/"
          element={
            <Navigate
              to={
                currentUser
                  ? `/dashboard/${tenantId}` // Redirigir a /dashboard/:tenantId
                  : "/login"
              }
              replace
            />
          }
        />
      </Routes>
    </Router>
  );
}

function ProtectedRoute({ children }) {
  const { currentUser } = useAuth();
  if (currentUser === undefined) {
    return <div>Loading...</div>;
  }
  return currentUser ? children : <Navigate to="/login" replace />;
}

export default App;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "./firebaseConfig";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";
import "./TourList.css";
import EditTourForm from "./EditTourForm.js"; // Asegúrate de tener este componente
import Dialog from "@mui/material/Dialog"; // Importar Dialog de Material UI
import DialogTitle from "@mui/material/DialogTitle"; // Importar DialogTitle

function TourList() {
  const [tours, setTours] = useState([]);
  const [editingTourId, setEditingTourId] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const { tenantId } = useParams();
  const pageSize = 4; // Número de tours por página
  const [expandedTourId, setExpandedTourId] = useState(null); // Estado para el tour expandido

  // Mueve la función fetchTours aquí, fuera del useEffect
  const fetchTours = async () => {
    setLoading(true);

    const toursQuery = query(
      collection(db, "tours"),
      where("tenantId", "==", tenantId),
      orderBy("nombre"),
      startAfter(lastVisible || ""),
      limit(pageSize)
    );

    const querySnapshot = await getDocs(toursQuery);
    const toursData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Eliminar duplicados usando un Set
    const uniqueTours = Array.from(new Set([...tours, ...toursData]));

    setTours(uniqueTours); // Actualizar el estado con los tours únicos
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);
  };

  useEffect(() => {
    fetchTours(); // Llama a la función dentro del useEffect
  }, [tenantId]); // Dependencia solo de tenantId

  const handleLoadMore = () => {
    if (!loading) {
      // Evitar cargar más si ya se está cargando
      fetchTours();
    }
  };

  const handleEditTour = (tourId) => {
    setEditingTourId(tourId);
  };

  const handleDeleteTour = async (tourId) => {
    if (window.confirm("¿Estás seguro de que quieres eliminar este tour?")) {
      await deleteDoc(doc(db, "tours", tourId));
      setTours(tours.filter((tour) => tour.id !== tourId));
    }
  };

  const handleReadMore = (tourId) => {
    console.log("Tour expandido:", tourId);
    console.log("Estado expandedTourId antes:", expandedTourId); // Nuevo log
    setExpandedTourId(expandedTourId === tourId ? null : tourId);
    console.log("Estado expandedTourId después:", expandedTourId); // Nuevo log
  };

  return (
    <div className="tour-list">
      <div className="tour-grid">
        {tours.map((tour) => (
          <div key={tour.id} className="tour-card">
            {editingTourId === tour.id ? (
              <Dialog // Envolver EditTourForm en Dialog
                open={true}
                onClose={() => setEditingTourId(null)}
                fullWidth
                maxWidth="lg" // Esto hará que el diálogo sea más grande
              >
                <DialogTitle>Editar Tour</DialogTitle>

                <EditTourForm
                  tour={tour}
                  onCancel={() => setEditingTourId(null)}
                />
              </Dialog>
            ) : (
              <>
                <img src={tour.image} alt={tour.nombre} />
                <h4>{tour.nombre}</h4>
                <p className={expandedTourId === tour.id ? "expanded" : ""}>
                  {tour.descripcionBreve.es}
                </p>

                <button onClick={() => handleEditTour(tour.id)}>Editar</button>
                <button onClick={() => handleDeleteTour(tour.id)}>
                  Eliminar
                </button>
              </>
            )}
          </div>
        ))}
        {loading && <p>Cargando más tours...</p>}
      </div>
      {!loading && tours.length > 0 && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Cargar Más
        </button>
      )}
    </div>
  );
}

export default TourList;

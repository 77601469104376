import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { app } from "./firebaseConfig"; // Tu archivo de configuración de Firebase

const AuthContext = createContext({
  currentUser: null,
  loading: true,
  tenantId: null, // Agregar tenantId al contexto
  signup: () => {},
  login: () => {},
  logout: () => {},
});
export function useAuth() {
  // No importar useAuth aquí
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth debe ser usado dentro de un AuthProvider");
  }
  return context;
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tenantId, setTenantId] = useState(null);

  const auth = getAuth(app);

  // Funciones para registrar, iniciar sesión y cerrar sesión
  async function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  // Escuchar cambios en el estado de autenticación
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        // Asignar el UID del usuario como tenantId
        setTenantId(user.uid);
      } else {
        setTenantId(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    loading,
    tenantId,
    signup,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Renderizar solo si no está cargando */}
    </AuthContext.Provider>
  );
}

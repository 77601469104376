import React, { useState, useEffect } from "react";
import { db, storage } from "./firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./EditTourForm.css"; // Tu archivo de estilos CSS
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/es"; // Asegúrate de tener la localización correcta
import { addDays, isSameDay, parseISO, format } from "date-fns";

const localizer = momentLocalizer(moment);

function EditTourForm({ tour, onCancel }) {
  const [nombre, setNombre] = useState(tour.nombre);
  const [descripcion, setDescripcion] = useState(tour.descripcion);
  const [precio, setPrecio] = useState(tour.precio);
  const [imagen, setImagen] = useState(tour.image);
  const [horasDisponibles, setHorasDisponibles] = useState(
    tour.horasDisponibles || []
  );
  const [newImage, setNewImage] = useState(null);
  const [opciones, setOpciones] = useState(tour.opciones || []);
  const [descripcionBreve, setDescripcionBreve] = useState(
    tour.descripcionBreve || ""
  );
  const [loading, setLoading] = useState(true);
  const [lang, setLang] = useState("en");

  const [horarios, setHorarios] = useState(tour.disponibilidad || []);
  const [fechasDisponibles, setFechasDisponibles] = useState([]);
  const [fechasNoDisponibles, setFechasNoDisponibles] = useState([]);
  const [eventosCalendario, setEventosCalendario] = useState([]);
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [hora, setHora] = useState("");
  const [capacidad, setCapacidad] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    if (horarios.length > 0) {
      const nuevasFechasDisponibles = horarios.map((horario) =>
        parseISO(horario.fecha)
      );
      setFechasDisponibles(nuevasFechasDisponibles);

      const todasLasFechas = generarTodasLasFechas();
      const nuevasFechasNoDisponibles = todasLasFechas.filter(
        (fecha) => !nuevasFechasDisponibles.some((d) => isSameDay(d, fecha))
      );
      setFechasNoDisponibles(nuevasFechasNoDisponibles);
    }
  }, [horarios]);

  useEffect(() => {
    if (tour && tour.disponibilidad) {
      const events = tour.disponibilidad.map((horario) => {
        const fechaInicio = moment(`${horario.fecha}T${horario.hora}`).toDate();
        const fechaFin = moment(fechaInicio)
          .add(tour.duration, "hours")
          .toDate();

        return {
          title: `Disponible (${horario.capacidad - horario.reservados} cupos)`,
          start: fechaInicio,
          end: fechaFin,
        };
      });
      setEventosCalendario(events);
    }
  }, [tour]);

  useEffect(() => {
    const events = horarios.map((horario) => {
      const fechaInicio = moment(`${horario.fecha}T${horario.hora}`).toDate();
      const fechaFin = moment(fechaInicio).add(tour.duration, "hours").toDate();

      return {
        title: `Disponible (${horario.capacidad - horario.reservados} cupos)`,
        start: fechaInicio,
        end: fechaFin,
      };
    });
    setEventosCalendario(events);
  }, [horarios, tour.duration]);

  // ... (otras funciones: handleAddHorario, handleHorarioChange, handleRemoveHorario, handleSubmit)

  function generarTodasLasFechas() {
    const hoy = new Date();
    const fechas = [];
    for (let i = 0; i < 30; i++) {
      fechas.push(addDays(hoy, i));
    }
    return fechas;
  }
  const handleAddOpcion = () => {
    setOpciones([...opciones, { nombre: "", precio: "" }]);
  };

  const handleOpcionChange = (index, field, value) => {
    const nuevasOpciones = [...opciones];
    nuevasOpciones[index][field] = value;
    setOpciones(nuevasOpciones);
  };

  const handleRemoveOpcion = (index) => {
    setOpciones(opciones.filter((_, i) => i !== index));
  };

  const handleAddHora = () => {
    setHorasDisponibles([...horasDisponibles, ""]); // Agregar un nuevo campo vacío
  };

  const handleHoraChange = (index, value) => {
    const nuevasHoras = [...horasDisponibles];
    nuevasHoras[index] = value;
    setHorasDisponibles(nuevasHoras);
  };

  const handleRemoveHora = (index) => {
    setHorasDisponibles(horasDisponibles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 1. Subir nueva imagen (si se seleccionó)
    let newImageUrl = imagen; // Mantener la imagen actual si no se seleccionó una nueva
    if (newImage) {
      const storageRef = ref(storage, `tour_images/${newImage.name}`);
      await uploadBytes(storageRef, newImage);
      newImageUrl = await getDownloadURL(storageRef);
    }
    const tourData = {
      // ... (otros datos del tour)
      horasDisponibles: horasDisponibles.filter((hora) => hora !== ""), // Filtrar horas vacías
    };
    // 2. Actualizar datos del tour en Firestore
    const tourRef = doc(db, "tours", tour.id);
    await updateDoc(tourRef, {
      nombre,
      descripcion,
      descripcionBreve,
      precio: parseFloat(precio), // Asegúrate de que el precio sea un número
      image: newImageUrl,
      horasDisponibles,
      opciones: opciones.filter(
        (opcion) => opcion.nombre !== "" && opcion.precio !== ""
      ),
      disponibilidad: horarios,
    });

    onCancel(); // Cerrar el formulario de edición
  };

  const handleLangChange = (newLang) => {
    setLang(newLang);
  };

  const handleAddRange = () => {
    if (rangeStart && rangeEnd && hora && capacidad) {
      const newHorarios = [];
      for (
        let d = new Date(rangeStart);
        d <= new Date(rangeEnd);
        d.setDate(d.getDate() + 1)
      ) {
        const formattedDate = format(d, "yyyy-MM-dd");
        newHorarios.push({
          fecha: formattedDate,
          hora,
          capacidad: parseInt(capacidad, 10),
          reservados: 0,
        });
      }
      setHorarios([...horarios, ...newHorarios]);
    }
  };

  // Función para eliminar las fechas seleccionadas
  const handleDeleteSelectedDates = () => {
    if (!rangeStart || !rangeEnd) return;

    const start = new Date(rangeStart);
    const end = new Date(rangeEnd);
    let date = new Date(start);

    const datesToRemove = [];
    while (date <= end) {
      datesToRemove.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    const updatedHorarios = horarios.filter(
      (horario) =>
        !datesToRemove.some((d) => isSameDay(new Date(horario.fecha), d))
    );

    setHorarios(updatedHorarios);
    setRangeStart(null);
    setRangeEnd(null);
  };

  return (
    <form onSubmit={handleSubmit} className="edit-tour-form">
      <label>Nombre del Tour</label>

      <input
        type="text"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
      />
      <label>Idioma</label>
      <select onChange={(e) => handleLangChange(e.target.value)} value={lang}>
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>
      <label htmlFor="descripcionBreve">Descripción Breve {[lang]}: </label>
      <textarea
        id="descripcionBreve"
        value={descripcionBreve[lang] || ""}
        onChange={(e) =>
          setDescripcionBreve({ ...descripcionBreve, [lang]: e.target.value })
        }
        required
      />
      <label>Descripción Tour {[lang]}:</label>

      <textarea
        value={descripcion[lang] || ""}
        onChange={(e) =>
          setDescripcion({ ...descripcion, [lang]: e.target.value })
        }
      />
      <label>Precio base</label>
      <input
        type="number"
        value={precio}
        onChange={(e) => setPrecio(e.target.value)}
      />
      <label>Agregar Imagen</label>
      <input type="file" onChange={(e) => setNewImage(e.target.files[0])} />

      <div className="opciones-container">
        <h3>Opciones del Tour</h3>
        {opciones.map((opcion, index) => (
          <div key={index} className="opcion-input">
            <input
              type="text"
              placeholder="Nombre de la opción"
              value={opcion.nombre}
              onChange={(e) =>
                handleOpcionChange(index, "nombre", e.target.value)
              }
            />
            <input
              type="number"
              placeholder="Precio"
              value={opcion.precio}
              onChange={(e) =>
                handleOpcionChange(index, "precio", e.target.value)
              }
            />
            <button type="button" onClick={() => handleRemoveOpcion(index)}>
              Eliminar
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddOpcion}>
          Agregar Opción
        </button>
      </div>

      <Calendar
        localizer={localizer}
        events={eventosCalendario}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        dayClassName={(date) => {
          if (fechasDisponibles.some((d) => isSameDay(d, date))) {
            return "disponible";
          } else if (fechasNoDisponibles.some((d) => isSameDay(d, date))) {
            return "no-disponible";
          }
          return "";
        }}
        excludeDates={fechasNoDisponibles}
      />

      <h3>Agregar Rango de Fechas</h3>
      <label>Fecha de Inicio</label>
      <input
        type="date"
        value={rangeStart ? format(rangeStart, "yyyy-MM-dd") : ""}
        onChange={(e) => setRangeStart(parseISO(e.target.value))}
      />
      <label>Fecha de Fin</label>
      <input
        type="date"
        value={rangeEnd ? format(rangeEnd, "yyyy-MM-dd") : ""}
        onChange={(e) => setRangeEnd(parseISO(e.target.value))}
      />
      <label>Hora</label>
      <input
        type="time"
        value={hora}
        onChange={(e) => setHora(e.target.value)}
      />
      <label>Capacidad</label>
      <input
        type="number"
        value={capacidad}
        onChange={(e) => setCapacidad(e.target.value)}
      />
      <button type="button" onClick={handleAddRange}>
        Agregar Rango
      </button>
      <button type="button" onClick={handleDeleteSelectedDates}>
        Eliminar Fechas
      </button>

      <h3>Horas Disponibles</h3>
      <div className="horas-disponibles">
        {horasDisponibles.map((hora, index) => (
          <div key={index} className="hora-input">
            <input
              type="time"
              value={hora}
              onChange={(e) => handleHoraChange(index, e.target.value)}
            />
            <button type="button" onClick={handleDeleteSelectedDates}>
              Eliminar
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddHora}>
          Agregar Hora
        </button>
      </div>

      {/* Campos para horas disponibles (puedes usar un componente separado para esto) */}
      {/* ... */}

      <button type="submit">Guardar Cambios</button>
      <button type="button" onClick={onCancel}>
        Cancelar
      </button>
    </form>
  );
}

export default EditTourForm;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom"; // Para enlazar a detalles de la reserva (opcional)
import "./BookingList.css"; // Tu archivo de estilos CSS
import { db } from "./firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";
import { format } from "date-fns";
import es from "date-fns/locale/es";

function BookingList() {
  const [bookings, setBookings] = useState([]);
  const { tenantId } = useParams();
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);

  const pageSize = 5; // Número de reservas por página

  const fetchBookings = async () => {
    setLoading(true);

    const bookingsQuery = query(
      collection(db, "bookings"),
      where("tenantId", "==", tenantId),
      orderBy("createdAt", "desc"),
      startAfter(lastVisible || ""),

      limit(pageSize)
    );

    const querySnapshot = await getDocs(bookingsQuery);
    const bookingsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Eliminar duplicados usando un Set
    const uniqueBookings = Array.from(new Set([...bookings, ...bookingsData]));

    setBookings(uniqueBookings); // Actualizar el estado con las reservas únicas
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);
  };

  useEffect(() => {
    fetchBookings(); // Llama a la función dentro del useEffect
  }, [tenantId]); // Dependencia solo de tenantId

  const handleLoadMore = () => {
    if (!loading) {
      fetchBookings();
    }
  };

  return (
    <div className="booking-list">
      {bookings.length === 0 ? (
        <p>No hay reservas aún.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID de Reserva</th>
              <th>Cliente</th>
              <th>Tour</th>
              <th>Total</th>
              <th>Fecha de reserva</th>
              <th>Fecha del Tour</th>
              <th>Hora del Tour</th>
              {/* ... otras columnas que quieras mostrar */}
              <th>Acciones</th> {/* Opcional: para editar/cancelar reservas */}
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking.id}>
                <td>{booking.id}</td>
                <td>{booking.nombre}</td>
                <td>{booking.tourNombre}</td>
                <td>${booking.precioTotal}</td>

                <td>
                  {format(
                    new Date(booking.createdAt.toDate()),
                    "dd/MM/yyyy HH:mm",
                    { locale: es }
                  )}
                </td>
                <td>
                  {format(new Date(booking.fecha.toDate()), "dd/MM/yyyy", {
                    locale: es,
                  })}
                </td>
                <td>{booking.hora}</td>
                {/* ... otras celdas con datos de la reserva */}
                <td>
                  <Link to={`/booking/${booking.id}`}>Ver Detalles</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {loading && <p>Cargando más reservas...</p>}
      {!loading &&
        lastVisible && ( // Mostrar el botón solo si hay más reservas disponibles
          <button className="load-more-button" onClick={handleLoadMore}>
            Cargar Más
          </button>
        )}
    </div>
  );
}

export default BookingList;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, storage } from "./firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "./Cuenta.css"; // Tu archivo de estilos CSS
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

function Cuenta() {
  const { tenantId } = useParams(); // Obtener el tenantId de la URL
  const [cliente, setCliente] = useState(null);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [paypalClientId, setPaypalClientId] = useState("");
  const [editando, setEditando] = useState(false);
  const [error, setError] = useState(null); // Estado para mensajes de error
  const [telefono, setTelefono] = useState("");
  const [numerosinpe, setNumerosinpe] = useState("");

  const [logo, setLogo] = useState(null); // Para almacenar el archivo de imagen del logo
  const [nombreCompania, setNombreCompania] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [previewImagen, setPreviewImagen] = useState(null);
  const [googleCalendarId, setGoogleCalendarId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCliente = async () => {
      try {
        const clienteDocRef = doc(db, "clientes", tenantId);
        const clienteDocSnap = await getDoc(clienteDocRef);
        if (clienteDocSnap.exists()) {
          const clienteData = clienteDocSnap.data();
          setCliente(clienteData);
          setNombre(clienteData.nombre || ""); // Cargar datos en los estados
          setEmail(clienteData.email || "");
          setPaypalClientId(clienteData.paypalClientId || "");
          setTelefono(clienteData.telefono || ""); // Cargar teléfono
          setNumerosinpe(clienteData.numerosinpe || ""); // Cargar teléfono
          setLogo(clienteData.logo || null); // Cargar logo (puede ser null si no hay)
          setNombreCompania(clienteData.nombrecompañia || "");
          setFacebook(clienteData.facebook || "");
          setInstagram(clienteData.instagram || "");
          setGoogleCalendarId(clienteData.googleCalendarId || ""); // Agregar esta línea
        } else {
          console.log("No se encontró el cliente.");
          // Puedes manejar el caso de que no se encuentre el cliente
        }
      } catch (error) {
        console.error("Error al obtener los datos del cliente:", error);
      }
    };

    fetchCliente();
  }, [tenantId]);

  const handleEditar = () => {
    setEditando(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setLogo(file);

    // Crear una URL de objeto para la vista previa
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImagen(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImagen(null);
    }
  };

  const handleGuardarCambios = async (e) => {
    e.preventDefault();
    setError(null); // Limpiar errores anteriores

    // Validación básica de campos
    if (!nombre || !email) {
      setError("Por favor, completa todos los campos obligatorios.");
      return;
    }

    try {
      let logoUrl = logo; // Mantener el logo actual si no se seleccionó uno nuevo
      if (previewImagen) {
        const storageRef = ref(storage, `compañia/${logo.nombrecompañia}`);
        await uploadBytes(storageRef, logo);
        logoUrl = await getDownloadURL(storageRef);
      }

      const clienteDocRef = doc(db, "clientes", tenantId);
      await updateDoc(clienteDocRef, {
        nombre,
        email,
        paypalClientId,
        telefono, // Agregar teléfono
        numerosinpe,
        logo: logoUrl, // Guardar la URL del logo en Firestore
        nombreCompania,
        facebook,
        instagram,
        googleCalendarId, // Agregar googleCalendarId
      });
      setEditando(false);
      // Puedes mostrar un mensaje de éxito aquí
    } catch (error) {
      console.error("Error al actualizar los datos del cliente:", error);
      setError(
        "Ocurrió un error al guardar los cambios. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <div className="cuenta">
      <button onClick={() => navigate(-1)}>Volver a al Dashboard</button>{" "}
      <h2>Información de la Cuenta</h2>
      {cliente ? (
        <>
          {editando ? (
            <form onSubmit={handleGuardarCambios}>
              {error && <p className="error-message">{error}</p>}{" "}
              {/* Mostrar mensaje de error */}
              <label htmlFor="fecha">Nombre Compañia:</label>
              <input
                type="text"
                value={nombreCompania}
                onChange={(e) => setNombreCompania(e.target.value)}
                placeholder="Nombre de la Compañía"
              />
              <label htmlFor="fecha">Nombre:</label>
              <input
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
                placeholder="Nombre"
              />
              <label htmlFor="fecha">Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Correo electrónico"
              />
              <label htmlFor="fecha">Telefono:</label>
              <input
                type="tel"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                placeholder="Teléfono"
              />
              <label htmlFor="fecha">Numero de Sinpe Movil:</label>
              <input
                type="tel"
                value={numerosinpe}
                onChange={(e) => setNumerosinpe(e.target.value)}
                placeholder="Numero de Sinpe Movil"
              />
              {cliente.logo && ( // Mostrar el logo actual si existe
                <div>
                  <h3>Logo Actual:</h3>
                  <img
                    src={cliente.logo}
                    alt="Logo actual"
                    key={cliente.logo}
                    onError={(e) =>
                      console.error("Error al cargar la imagen:", e.target.src)
                    }
                    style={{ maxWidth: "200px", marginBottom: "10px" }}
                  />
                </div>
              )}
              <div>
                <label>Logo:</label>

                <input type="file" onChange={handleImageChange} />
                {previewImagen && (
                  <img
                    src={previewImagen}
                    alt="Vista previa de la imagen"
                    style={{ maxWidth: "200px" }}
                  />
                )}
              </div>
              {/* Campo para subir el logo */}
              <label htmlFor="fecha">Facebook:</label>
              <input
                type="text"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
                placeholder="Facebook"
              />
              <label htmlFor="fecha">Instagram:</label>
              <input
                type="text"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                placeholder="Instagram"
              />
              <label htmlFor="fecha">PayPal Client ID:</label>
              <input
                type="text"
                value={paypalClientId}
                onChange={(e) => setPaypalClientId(e.target.value)}
                placeholder="PayPal Client ID"
              />
              <label htmlFor="fecha">Calendario ID:</label>
              <input
                type="text"
                value={googleCalendarId}
                onChange={(e) => setGoogleCalendarId(e.target.value)}
                placeholder="ID del Calendario de Google"
              />
              <button type="submit">Guardar Cambios</button>
              <button type="button" onClick={() => setEditando(false)}>
                Cancelar
              </button>
            </form>
          ) : (
            <div>
              <p>
                <strong>Nombre de la Compañía:</strong> {cliente.nombrecompañia}
              </p>

              <p>
                <strong>Nombre:</strong> {cliente.nombre}
              </p>
              <p>
                <strong>Email:</strong> {cliente.email}
              </p>
              <p>
                <strong>Teléfono:</strong> {cliente.telefono}
              </p>
              <p>
                <strong>Numero de Sinpe:</strong> {cliente.numerosinpe}
              </p>
              <p>
                <strong>Facebook:</strong> {cliente.facebook}
              </p>
              <p>
                <strong>Instagram:</strong> {cliente.instagram}
              </p>

              <p>
                <strong>PayPal Client ID:</strong> {cliente.paypalClientId}
              </p>

              <p>
                <strong>Calendario ID:</strong> {cliente.googleCalendarId}
              </p>

              <button onClick={handleEditar}>Editar</button>
            </div>
          )}
        </>
      ) : (
        <p>Cargando...</p>
      )}
    </div>
  );
}

export default Cuenta;
